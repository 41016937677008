const casinoHotels = [
  {
    name: 'Hotel Warszawa',
    description: 'Hotel Warszawa oferuje niezrównany luksus i rozrywkę z przestronnymi pokojami, wykwintnymi restauracjami i rozbudowaną powierzchnią kasyna.',
    image: '/assets/img-1.jpg',
    link: 'https://www.hotelwarszawa.pl/',
    rating: '9.4',
    reviews: '1500',
    price: '300 zł za noc'
  },
  {
    name: 'Kasyno Royal Kraków',
    description: 'Położone w sercu Krakowa, Kasyno Royal oferuje światowej klasy doświadczenia kasynowe z zapierającymi dech w piersiach widokami, luksusowymi pokojami i wykwintnymi restauracjami.',
    image: '/assets/img-1.webp',
    link: 'https://www.kasynoroyal.pl/',
    rating: '9.2',
    reviews: '2000',
    price: '280 zł za noc'
  },
  {
    name: 'Hotel Gdańsk',
    description: 'Hotel Gdańsk zapewnia wyjątkowe doświadczenia z jego luksusowymi zakwaterowaniami, różnorodnymi opcjami gastronomicznymi i tętniącym życiem kasynem.',
    image: '/assets/img-2.webp',
    link: 'https://www.hotelgdansk.pl/',
    rating: '8.8',
    reviews: '1200',
    price: '250 zł za noc'
  },
  {
    name: 'Crown Poznań',
    description: 'Crown Poznań łączy luksus i ekscytację dzięki przestronnym pokojom, wykwintnym restauracjom i rozległym obiektom kasynowym.',
    image: '/assets/img-3.jpg',
    link: 'https://www.crownpoznan.pl/',
    rating: '9.0',
    reviews: '1100',
    price: '270 zł za noc'
  },
  {
    name: 'The Star Wrocław',
    description: 'The Star Wrocław oferuje niezrównane doświadczenia z luksusowymi zakwaterowaniami, różnorodnymi opcjami gastronomicznymi i nowoczesnym kasynem.',
    image: '/assets/img-4.jpg',
    link: 'https://www.thestarwroclaw.pl/',
    rating: '9.1',
    reviews: '1600',
    price: '290 zł za noc'
  },
  {
    name: 'SkyCity Łódź',
    description: 'SkyCity Łódź to premierowy cel podróży, oferujący luksusowe zakwaterowanie, wykwintne posiłki i ekscytujące doświadczenia kasynowe w historycznym otoczeniu.',
    image: '/assets/img-5.jpg',
    link: 'https://www.skycitylodz.pl/',
    rating: '8.7',
    reviews: '900',
    price: '220 zł za noc'
  }
];

export default casinoHotels;
