import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import { Button } from '@headlessui/react';
import Contacts from './components/Contacts';

const HomePage = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto mt-24 mb-20 p-4">
        <header className="text-center my-8">
          <h1 className="text-4xl font-bold">Najlepsze Hotele Kasynowe w Polsce</h1>
          <p className="mt-4 text-lg">
            Poznaj ekskluzywny wybór najlepszych hoteli kasynowych w Polsce, gdzie luksus, rozrywka i najwyższej klasy udogodnienia łączą się, tworząc niezapomniane wrażenia.
          </p>
          <img className="w-full mt-5" src="/assets/img-6.jpg" alt="hotele" />
        </header>

        <section className="my-8">
          <h2 className="text-3xl font-semibold mb-4">W Pośpiechu?</h2>
          <p className="mb-4">Przeglądaj naszą starannie dobraną listę najlepiej ocenianych hoteli w Polsce, znanych z doskonałych zakwaterowań i wyjątkowych usług:</p>
          <table className="w-full text-left table-auto border-collapse bg-slate-50 rounded my-10 shadow-md">
            <thead className='bg-logo'>
              <tr>
                <th className="p-4">Nazwa Hotelu</th>
                <th className="p-4">Ocena Zakwaterowania</th>
                <th className="p-4">Cena, zł</th>
                <th className="p-4">Dostępność</th>
              </tr>
            </thead>
            <tbody>
              {casinoHotels.map((hotel, index) => (
                <tr key={index} className="border-t">
                  <td className="px-4 py-2">{hotel.name}</td>
                  <td className="px-4 py-2">{hotel.rating} <svg className="inline-block" xmlns="http://www.w3.org/2000/svg" height="14px" width="14px" version="1.1" id="Capa_1" viewBox="0 0 47.94 47.94" >
<path fill="#D17A22" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757  c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042  c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685  c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528  c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956  C22.602,0.567,25.338,0.567,26.285,2.486z"/>
</svg></td>
                  <td className="px-4 py-2">{hotel.price}</td>
                  <td className="px-4 py-2">
                    <a href={hotel.link} className="text-primary underline">Sprawdź dostępność</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button as="a" href="/hotels" className="font-bold rounded bg-primary py-2 px-4 text-sm text-white data-[hover]:bg-sky-500 data-[hover]:data-[active]:bg-sky-700 transition-all ease-in-out duration-150">
            Zobacz więcej
          </Button>
        </section>

        <section className="my-8">
          {infoBlocks.map((block, index) => (
            <div key={index} className="flex flex-col lg:flex-row items-center my-20">
              {index % 2 === 0 ? (
                <>
                  <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                  <div className="p-6 lg:w-1/2">
                    <h3 className="text-2xl font-semibold mb-4">{block.title}</h3>
                    <p className="text-gray-600">{block.description}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-6 lg:w-1/2">
                    <h3 className="text-2xl font-semibold mb-4">{block.title}</h3>
                    <p className="text-gray-600">{block.description}</p>
                  </div>
                  <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                </>
              )}
            </div>
          ))}
        </section>
      </div>
      <Contacts />
      <Footer />
    </>
  );
};

const infoBlocks = [
  {
    title: 'O Polsce',
    description: 'Polska jest znana z zapierających dech w piersiach krajobrazów, różnorodnych ekosystemów i dynamicznych centrów miejskich. Od naturalnego piękna Tatr po urokliwe jeziora na Mazurach, ten kraj oferuje mnóstwo unikalnych doświadczeń. Polskie miasta są centrami kultury, kuchni i handlu, odzwierciedlając połączenie nowoczesności i tradycji. Polska jest przyjaznym krajem z gościnnymi mieszkańcami, idealnym dla podróżników poszukujących zarówno przygody, jak i relaksu. Klimat kraju, od umiarkowanego na północy po cieplejszy na południu, zapewnia, że zawsze jest coś ekscytującego do odkrycia.',
    image: '/assets/img-7.jpg'
  },
  {
    title: 'Główne Miasta',
    description: 'Polskie główne miasta, w tym Warszawa, Kraków, Wrocław i Gdańsk, oferują różnorodne atrakcje i doświadczenia. Warszawa, ze swoją nowoczesną architekturą i historycznymi miejscami, jest miastem kontrastów, łączącym przeszłość z teraźniejszością. Kraków jest znany jako kulturalna stolica Polski, z bogatą historią i artystycznym dziedzictwem. Wrocław oferuje relaksujący styl życia z naciskiem na aktywności na świeżym powietrzu i piękne tereny zielone. Gdańsk, z bogatą historią i piękną nadmorską scenerią, jest jednym z najpiękniejszych miast w Polsce. Te miasta ukazują różnorodność miejską, która czyni Polskę fascynującym miejscem.',
    image: '/assets/img-8.webp'
  },
  {
    title: 'Luksusowe Hotele',
    description: 'Polska oferuje wiele luksusowych hoteli, które zapewniają gościom niezrównaną obsługę, wyśmienite jedzenie i najwyższej klasy udogodnienia. Te hotele są strategicznie rozmieszczone, oferując zapierające dech w piersiach widoki i wygodny dostęp do kluczowych atrakcji. Goście mogą korzystać z światowej klasy spa, nowoczesnych centrów fitness i gourmet restauracji. Dbałość o szczegóły i indywidualne podejście sprawiają, że każdy pobyt jest niezapomniany. Niezależnie od celu podróży, luksusowe hotele w Polsce obiecują wyjątkowe doświadczenia, łącząc komfort z wyrafinowaniem.',
    image: '/assets/img-9.jpg'
  },
  {
    title: 'Hotele Kasynowe',
    description: 'Najlepsze polskie hotele kasynowe łączą luksusowe zakwaterowanie z najwyższej klasy obiektami do gry i rozrywki. Te obiekty oferują pełną gamę opcji gier, w tym automaty, gry stołowe i ekskluzywne pokoje pokerowe. Goście mogą także cieszyć się występami na żywo, wykwintnymi posiłkami i usługami VIP, wszystko w luksusowych wnętrzach swoich hoteli. Dynamiczna atmosfera tych hoteli kasynowych zapewnia ekscytujące tło zarówno dla entuzjastów gier, jak i przypadkowych odwiedzających. Dzięki wysokim standardom obsługi i rozrywki, polskie hotele kasynowe zapewniają niezapomniane i przyjemne pobyty.',
    image: '/assets/img-2.webp'
  }
];

export default HomePage;
