import React, { useState } from 'react';
import {Button} from "@headlessui/react";

const Contacts = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPopup(true);
    // Reset form
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <div id="contacts" className="container md:flex md:gap-10 justify-between mx-auto my-20 p-4">
      <div className="text-left my-8 md:col-5">
        <h2 className="text-3xl font-semibold mb-4">SKONTAKTUJ SIĘ Z NAMI</h2>
        <p className="text-5xl font-bold mb-4">Jeśli masz jakiekolwiek pytania, skontaktuj się z nami</p>
        <div className="flex mt-10 items-center mb-4">
          <svg className="w-10 h-10 mr-2" fill="currentColor" viewBox="0 0 20 20"><path d="M2.5 5.5a1 1 0 00-.39 1.44 12.04 12.04 0 0015.79 5.39 1 1 0 10-1-1.73A10.04 10.04 0 013.88 6.07a1 1 0 00-1.38-.57z" /><path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm1 15H9v-2h2v2zm0-4H9V5h2v6z" /></svg>
          <div>
            <p className="font-semibold text-xl">Napisz do nas</p>
            <p className="text-xl">info@polskiekasynogames.com</p>
          </div>
        </div>
       
      </div>
      <form className="mt-8 md:col-6" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Twoje imię"
            className="border p-2 rounded"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Twój email"
            className="border p-2 rounded"
            required
          />
        </div>
        <input
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Temat"
          className="border p-2 rounded w-full mt-4"
          required
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Wiadomość"
          className="border p-2 rounded w-full mt-4 h-32"
          required
        ></textarea>
         <Button type="submit" className="mt-5 font-bold rounded bg-orange-600 py-2 px-4 text-sm text-white data-[hover]:bg-orange-500 data-[hover]:data-[active]:bg-orange-700 transition-all ease-in-out duration-150">
     Wyślij wiadomość
    </Button>
      </form>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white py-10 px-20 rounded shadow-lg text-center">
            <h2 className="text-3xl font-bold mb-4">Dziękuję!</h2>
            <h3 className="text-md mb-4">Formularz przesłany pomyślnie.</h3>
            <button
              className="bg-orange-600 text-white p-2 rounded mt-4"
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contacts;
