import React from "react";
import { Link } from "react-scroll";

export default function Header() {
  return (
    <header className="bg-dark fixed w-full top-0 shadow-md">
      <nav className="flex justify-between items-center py-4 px-8">
        <div className="flex items-center justify-between w-full text-white">
          <div className="flex items-center gap-2">
          <img src="/assets/favicon.png" alt="logo" className="w-8 h-8 object-contain" />
          <a href="/" className="text-xl text-logo uppercase font-bold">
          Polskie Kasynogames
          </a>
          </div>
          <ul className="flex ml-10 space-x-4 text-sm font-bold">
            <li>
              <a href="/" className="hover:text-gray-300">Dom</a>
            </li>
            <li>
              <a href="/hotels" className="hover:text-gray-300">Hotele kasyno</a>
            </li>
            <li>
              <Link
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70} 
                duration={500}
                className="bg-logo p-2 rounded text-white cursor-pointer hover:bg-green-700"
              >
                Skontaktuj się z nami
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
